<!--  -->
<template>
  <div class="approval-detail">
    <div class="base-info card">
      <div class="row1">
        {{ formInfo.formValue.userName }}提交的{{ processConfigInfo.name }}
      </div>

      <div class="row2">
        <div class="time">{{ formInfo.formValue.createTime }}</div>
      </div>
      <div
        :class="{
          row3: true,
          approving: 1 == formInfo.status,
          accept: 2 == formInfo.status,
          reject: 3 == formInfo.status,
          cancel: 4 == formInfo.status,
        }"
      >
        {{ formInfo.status | formatStatus }}
      </div>
    </div>

    <div class="values card">
      <ul class="value-ul">
        <li class="item">
          <div class="label">审批编号</div>

          <div class="value">
            {{ applyNo }}
          </div>
          <!-- <div v-else class="value">{{ applyNo }}</div> -->
        </li>
        <li class="item">
          <div class="label">业务编码</div>

          <div class="value">
            {{ bizCode }}
          </div>
        </li>

        <li class="item" v-for="(item, index) in resultList" :key="index">
          <div class="label">{{ item.title }}</div>
          <template v-if="'UploadImg' == item.type">
            <template v-if="item.value && item.value.length">
              <el-image
                v-for="(imgItem, imgIndex) in item.value"
                :key="imgIndex"
                class="img-value"
                :src="imgItem.url"
                :preview-src-list="[imgItem.url]"
              />
            </template>
            <div v-else class="value">暂无上传</div>
          </template>

          <template v-else-if="'UploadFile' == item.type">
            <div
              class="file-box"
              v-for="fileItem in item.value"
              :key="fileItem.objectKey"
              @click="goFileScan(fileItem)"
            >
              <span class="file-value" :href="fileItem.url">{{
                fileItem.name
              }}</span>
            </div>
          </template>
          <template v-else-if="'timeRange' == item.type">{{
            formatArray(item.value)
          }}</template>
          <template v-else-if="'PassInput' == item.type">{{
            randomStr(item.value)
          }}</template>
          <template v-else-if="'ALink' == item.type">
            <el-link
              :underline="item.props ? item.props.underline : true"
              :type="item.props ? item.props?.type : ''"
              :href="item.props ? item.props.href : ''"
              :target="item.props ? item.props.target : ''"
              :disabled="item.props ? item.props.disabled : false"
              >{{ item.props ? item.props.text : "" }}</el-link
            >
          </template>

          <div v-else class="value">{{ item.value | formatValue }}</div>
        </li>
      </ul>
    </div>
    <div class="process card">
      <div class="title">审批流程</div>
      <ul class="process-ul">
        <li v-for="(item, index) in nodesList" :key="index" class="item">
          <div class="left">
            <img
              v-if="
                !item.isTaskNode &&
                item.nodeComment &&
                item.nodeComment.avatarUrl
              "
              :src="item.nodeComment.avatarUrl"
              alt=""
              class="avatar"
            />
            <img v-else :src="approvalAvatar" alt="" class="avatar" />
            <div class="split"></div>
          </div>
          <div class="right" v-if="item.isTaskNode">
            <div class="row1">
              <div class="name">
                {{ item.activityName }}
              </div>
              <div class="time">{{ item.endTime }}</div>
            </div>

            <div class="status" v-if="item.activityName !== '申请'">
              {{ item.assigneeName }} {{ item.isFinish | formatIsFinish }}
            </div>
            <div class="status" v-if="item.activityName == '申请'">
              {{ item.assigneeName }}
            </div>
            <div class="comment" v-if="item.comment">
              {{ item.comment | formatComment }}
            </div>
          </div>
          <div class="right" v-else>
            <div class="row1">
              <div class="name">
                <span style="color: #1e69c5">{{
                  item.nodeComment.userName
                }}</span>
                添加了评论
              </div>
            </div>
            <!-- 评论 -->
            <div v-if="item.nodeComment">
              <div class="comment-item">
                <div class="comment-content">
                  {{ item.nodeComment.comment }}
                </div>
                <div class="comment-img" v-if="item.nodeComment.imgUrls">
                  <el-image
                    v-for="(img, u) in item.nodeComment.imgUrls"
                    class="img"
                    :key="u"
                    :src="img"
                    alt=""
                    :preview-src-list="item.nodeComment.imgUrls"
                  />
                </div>
                <div class="comment-file" v-if="item.nodeComment.files">
                  <div
                    class="file"
                    v-for="(file, f) in item.nodeComment.files"
                    :key="f"
                  >
                    <div
                      class="file-name"
                      @click="handleClickSee(file.fileUrl)"
                    >
                      <div class="file-left">
                        <img
                          class="icon"
                          fit="contain"
                          :src="iconName(file.fileType)"
                        />
                      </div>
                      <div class="right-file">
                        <p class="name">{{ file.fileName }}</p>
                        <p class="size">{{ file.fileSize }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 操作按钮 -->
    <div class="footer-buttons">
      <div class="icon-text-comment" @click="handleComment">
        <van-icon name="chat-o" class="img" />
        <span class="text">评论</span>
      </div>
      <van-button
        v-if="formInfo.status == 1 && searchType == '3' && isIndex"
        class="pass"
        @click="approval(true)"
        >同意</van-button
      >
      <van-button
        v-if="formInfo.status == 1 && searchType == '3' && isIndex"
        class="reject-btn"
        @click="approval(false)"
        >拒绝</van-button
      >
      <!-- 撤销 -->
      <div
        class="icon-text"
        v-if="
          searchType == '1' &&
          staffCode == formInfo.formValue.staffCode &&
          formInfo.status == 1
        "
        @click="handleCancel"
      >
        <img :src="cancel" alt="" class="img" />
        <span class="text">撤销</span>
      </div>
      <!-- 编辑 -->
      <div
        class="icon-text"
        v-if="
          [3, 4].indexOf(formInfo.status) > -1 &&
          staffCode == formInfo.formValue.staffCode &&
          searchType == '1'
        "
        @click="handleReEdit"
      >
        <img :src="edit" alt="" class="img" />
        <span class="text">编辑</span>
      </div>
    </div>

    <!-- 审批意见 -->
    <van-popup v-model="formVisible" round>
      <div class="approval-box">
        <div class="title">审批意见</div>
        <van-form class="app">
          <textarea
            v-model="form.message"
            class="message"
            placeholder="请输入审批意见（非必填）"
          />
          <div class="footer">
            <div @click="close" class="cancel">取消</div>
            <div @click="onsubmit" class="confirm">确定</div>
          </div>
        </van-form>
      </div>
    </van-popup>
    <!-- 评论 -->
    <van-popup v-model="showComment" round position="bottom">
      <div class="comment-box">
        <div class="title">评论</div>
        <van-form class="app" v-if="showComment">
          <van-field
            class="input-text"
            name="name"
            v-model.trim="commentForm.comment"
            rows="1"
            maxlength="255"
            type="textarea"
            placeholder="评论"
          />
          <div class="upload-btn-box">
            <div class="upload-box">
              <van-uploader
                class="upload-img"
                :max-size="10 * 1024 * 1024"
                :before-read="beforeRead"
                :after-read="afterRead"
                @oversize="onOversize"
                :max-count="3"
                accept=".jpg,.jpeg,.png"
              >
                <span class="upload-btn">
                  <van-icon name="photo-o" />
                  图片
                </span>
              </van-uploader>
            </div>
            <div class="upload-box">
              <van-uploader
                class="upload-img"
                :max-size="10 * 1024 * 1024"
                :before-read="beforeReadFile"
                :after-read="afterReadFile"
                @oversize="onOversize"
                :max-count="3"
                type="file"
                accept=""
              >
                <span class="upload-btn">
                  <van-icon name="link-o" />
                  附件
                </span></van-uploader
              >
            </div>
          </div>
          <!-- 图片编码的集合，（多个用逗号隔开，限制三个图片） -->
          <div v-if="imgList.length > 0">
            <div class="img-box">
              <div
                class="img-item"
                v-for="(img, i) in imgList"
                :key="i"
                @click="delImg(img, i)"
              >
                <img class="img" :src="img.url" alt="" />
                <van-icon name="delete-o" class="x" />
              </div>
            </div>
          </div>

          <!-- 附件 -->
          <div v-if="fileList.length > 0">
            <div
              class="file-item"
              v-for="(file, i) in fileList"
              :key="i"
              @click="delCommentFile(file, i)"
            >
              <span class="name">{{ file.name }}</span>

              <van-icon name="cross" class="x" />
            </div>
          </div>
          <!-- 操作按钮 -->
          <div class="opt-text">
            <span @click="cancelComment">取消</span>
            <span @click="sureComment" :loading="loading" class="sure"
              >确定</span
            >
          </div>
        </van-form>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  schedule,
  queryProcessConfigInfo,
  uploadFile,
  addComment,
} from "@/api/processApplyInfo";
import { queryFormInfo } from "@/api/processForm";
import approvalAvatar from "@/assets/images/approvalDetail/approvalAvatar.png";
import edit from "@/assets/images/approvalDetail/edit.png";
import cancel from "@/assets/images/approvalDetail/cancel.png";
import Cookies from "js-cookie";
import { stop, complete, cancelProcess } from "@/api/processApplyInfo";
import { Toast, Dialog } from "vant";
import { cartList } from "@/api/cart/manage/index.js";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      processConfigInfo: {},
      formInfo: {
        formValue: {},
      },
      nodesList: [],
      approvalAvatar,
      edit,
      cancel,
      formVisible: false,
      type: true,
      form: {
        applyCode: "",
        message: "",
        taskId: "",
        workFlowKey: "process_simple_apply",
        processInstId: "",
      },
      showComment: false,
      commentForm: {
        applyCode: "", //申请编码
        comment: "", //评论内容255
        imgCodes: "", //图片编码的集合，（多个用逗号隔开，限制三个图片）
        fileCodes: "", //文件编码的集合，（多个用逗号隔开，限制三个文件）
        formValue: {}, //表单信息
        processCode: "", //流程编码
      },
      imgList: [],
      fileList: [],
      loading: false,
      carList: [],
    };
  },
  name: "approvalDetail",
  components: { TopWrapper },
  computed: {
    resultList() {
      if (!this.formInfo.configForm) {
        return [];
      }
      if (!this.formInfo.formValue.formContent) {
        return [];
      }
      let configForm = JSON.parse(this.formInfo.configForm);
      let formContent = JSON.parse(this.formInfo.formValue.formContent);
      configForm.map((item) => {
        if (this.tagKey == "CAR_APPLY_APPROVE") {
          if (item.field === "cartCode") {
            let obj = this.carList.find((e) => {
              return formContent[item.field] == e.bizCode;
            });
            item.value = obj ? obj.platingNumber : "";
          } else if (item.field === "cardType") {
            item.value = formContent[item.field] == "1" ? "是" : "否";
          } else if (item.field === "passengers") {
            item.value = formContent[item.field]
              .map((e) => {
                return e.userName;
              })
              .toString();
          } else {
            item.value = formContent[item.field];
          }
        } else {
          item.value = formContent[item.field];
        }
      });

      return configForm;
    },

    staffCode() {
      let result = localStorage.getItem("staffCode_H5");
      return result;
    },
    bizCode() {
      return this.$route.query.bizCode;
    },

    searchType() {
      return this.$route.query.searchType;
    },
    applyNo() {
      return this.$route.query.applyNo;
    },
    tagKey() {
      return this.$route.query.tagKey || "";
    },

    isIndex() {
      let indexNode = this.nodesList.find((item) => {
        return 0 == item.isFinish;
      });
      if (!indexNode) {
        return false;
      }
      let staffInfo = indexNode.staffInfo;
      let assignee = localStorage.getItem("staffCode_H5");
      let indexAssignee = staffInfo.find((item) => {
        return item.assignee == assignee;
      });
      if (indexAssignee) {
        return true;
      } else {
        return false;
      }
    },
  },
  filters: {
    formatIsFinish(val) {
      let rules = {
        0: "审核中",
        1: "通过",
        3: "拒绝",
      };
      return rules[val] || val;
    },
    formatStatus(val) {
      let rules = {
        1: "审批中",
        2: "审批通过",
        3: "审批驳回",
        4: "已撤销",
      };
      return rules[val] || val;
    },

    formatComment(val) {
      let arr = val || [];
      return arr.toString();
    },

    formatValue(val) {
      if (typeof val == "object") {
        return val.toString();
      } else {
        return val;
      }
    },
  },
  async created() {
    await this.getFormInfo();
    await this.getProcessConfigInfo();
    await this.getNodesList();
    await this.getList();
  },
  methods: {
    formatArray(value) {
      let str = "";
      if (Array.isArray(value)) {
        str = value.join("~");
      } else {
        str = value;
      }
      return str;
    },
    randomStr(value) {
      let str = "";
      if (!value) return "";
      let arr = value.length;
      for (let i = 0; i < arr; i++) {
        str += "*";
      }
      return str;
    },
    getList() {
      cartList({
        pageNum: 1,
        pageSize: 99,
      }).then((res) => {
        this.carList = res.data.records;
      });
    },
    async getFormInfo() {
      await queryFormInfo({
        processApplyCode: this.$route.query.bizCode,
      }).then((res) => {
        this.formInfo = res.data;
      });
    },

    async getProcessConfigInfo() {
      await queryProcessConfigInfo({
        processCode: this.$route.query.processCode,
      }).then((res) => {
        this.processConfigInfo = res.data;
      });
    },

    async getNodesList() {
      await schedule({
        processCode: this.$route.query.processCode,
        applyCode: this.$route.query.bizCode,
        isComment: true,
        formValue: JSON.parse(this.formInfo.formValue.formContent),
      }).then((res) => {
        this.nodesList = res.data.hisActivityList;
      });
    },

    approval(type) {
      this.type = type;
      this.formVisible = true;
    },

    close() {
      this.formVisible = false;
    },
    onsubmit() {
      const params = {
        ...this.form,
        applyCode: this.$route.query.bizCode,
        processInstId: this.processConfigInfo.processCode,
      };
      if (this.type) {
        complete(params).then((res) => {
          Toast("审批已同意");
          this.formVisible = false;
          this.$router.back();
        });
      } else {
        stop(params).then((res) => {
          Toast("审批已拒绝");
          this.formVisible = false;
          this.$router.back();
        });
      }
    },

    handleCancel() {
      Dialog.confirm({
        title: "确定撤销",
        message: "是否确认撤销当前审批？",
      }).then(
        (res) => {
          cancelProcess({
            applyCode: this.$route.query.bizCode,
          }).then((res) => {
            Toast("撤消成功");
            this.$router.back();
          });
        },
        (rej) => {}
      );
    },
    handleReEdit() {
      this.$router.push({
        path: "/approvalCreat",
        query: {
          bizCode: this.$route.query.processCode,

          applyCode: this.$route.query.bizCode,
        },
      });
    },
    handleComment() {
      this.showComment = true;
    },

    goFileScan(item) {
      // 创建下载任务
      if (window.plus) {
        const picurl = item.url,
          that = this;
        //图片保存到手机后的路径
        let picname = `_downloads/${item.name}`;
        var dtask = plus.downloader.createDownload(
          picurl,
          {
            filename: picname,
          },
          function (d, status) {
            // 下载完成
            if (status == 200) {
              // plus.gallery.save(
              //   d.filename, //这个很关键记得要填d 返回的名字
              //   function () {
              //     //保存到相册方法
              //     that.show = false;
              //     that.$toast("已保存到手机");
              //   },
              //   function (e) {
              //     that.show = false;
              //     that.$toast("保存失败，请重试！");
              //   }
              // );
              // that.$toast("已保存到手机相册");
              plus.runtime.openFile(d.filename, {}, function (e) {
                that.$toast("无法打开此文件");
              });
            } else {
              that.$toast("Download failed: " + status);
            }
          }
        );
        dtask.start(); //开始下载
      } else {
        window.open(item.url);
      }
    },
    iconName(fileType) {
      switch (fileType) {
        case "pdf":
          return require("@/assets/images/fileManager/pdf.png");
        case "ppt":
        case "pptx":
          return require("@/assets/images/fileManager/ppt.png");
        case "xls":
        case "xlsx":
          return require("@/assets/images/fileManager/xls.png");
        case "doc":
        case "docx":
        case "txt":
          return require("@/assets/images/fileManager/doc.png");
        case "rar":
        case "zip":
          return require("@/assets/images/fileManager/rar.png");
        default:
          return require("@/assets/images/fileManager/txt.png");
      }
    },
    // 返回布尔值
    beforeRead(file) {
      const isType = ["image/png", "image/jpeg"].includes(file.type);
      if (!isType) {
        Toast("请上传 jpg/png 格式图片");
        return false;
      }
      if (this.imgList.length > 2) {
        Toast("上传图片不超过3张!");
        return;
      }
      return true;
    },
    afterRead(file) {
      this.loading = true;
      let form = new FormData();
      form.append("file", file.file);
      Toast.loading({
        message: "文件上传中...",
        forbidClick: true,
        duration: 0,
      });

      uploadFile(form).then((res) => {
        Toast.clear();
        this.loading = false;
        if (res) {
          let { data, code, msg } = res;
          if (code == 200) {
            Toast({
              type: "success",
              message: msg,
            });

            this.imgList.push({
              name: file.file.name,
              code: data.fileCode,
              url: data.fileUrl,
            });
            // console.log(this.imgList);
          }
        }
      });
    },
    onOversize(file) {
      Toast("文件大小不能超过 10M");
    },
    beforeReadFile(file) {
      const isType = ["image/png", "image/jpeg"].includes(file.type);
      if (isType) {
        Toast("上传附件不支持 JPG/PNG 格式!");
        return false;
      }
      if (this.fileList.length > 2) {
        Toast("上传附件不超过3份!");
        return false;
      }
      return true;
    },
    afterReadFile(file) {
      this.loading = true;
      let form = new FormData();
      form.append("file", file.file);
      Toast.loading({
        message: "文件上传中...",
        forbidClick: true,
        duration: 0,
      });

      uploadFile(form).then((res) => {
        Toast.clear();
        this.loading = false;
        if (res) {
          let { data, code, msg } = res;
          if (code == 200) {
            Toast({
              type: "success",
              message: msg,
            });
            this.fileList.push({
              name: file.file.name,
              code: data.fileCode,
              url: data.fileUrl,
            });
          }
        }
      });
    },
    //删除文件
    delCommentFile(file, i) {
      let arr = [];
      this.fileList.map((e) => {
        if (e.code != file.code) {
          arr.push(e);
        }
      });
      this.fileList = arr;
    },
    // 删除图片
    delImg(img, i) {
      let arr = [];
      this.imgList.map((e) => {
        if (e.code != img.code) {
          arr.push(e);
        }
      });
      this.imgList = arr;
    },

    cancelComment() {
      this.imgList = [];
      this.fileList = [];
      this.commentForm = {
        applyCode: "", //申请编码
        comment: "", //评论内容255
        imgCodes: "", //图片编码的集合，（多个用逗号隔开，限制三个图片）
        fileCodes: "", //文件编码的集合，（多个用逗号隔开，限制三个文件）
        formValue: {}, //表单信息
        processCode: "", //流程编码
      };
      this.showComment = false;
    },
    sureComment() {
      this.loading = true;
      const params = {
        ...this.commentForm,
      };
      let fileArrCodes = this.fileList.map((e) => {
        return e.code;
      });
      let imgArrCodes = this.imgList.map((e) => {
        return e.code;
      });
      params.applyCode = this.$route.query.bizCode;
      params.processCode = this.$route.query.processCode;
      params.fileCodes = fileArrCodes.toString();
      params.imgCodes = imgArrCodes.toString();

      addComment(params).then((res) => {
        if (res) {
          this.loading = false;
          let { code, msg } = res;
          if (code == 200) {
            Toast({
              type: "success",
              message: msg,
            });
            this.cancelComment();
            this.getFormInfo();
            this.getProcessConfigInfo();
            this.getNodesList();
          }
        }
      });
    },
    //下载
    handleClickSee(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.approval-detail {
  min-height: 100%;
  background: #fafafa;
  width: 7.5rem;
  position: relative;
  padding: 1px;
  .card {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    background: #fff;
    margin-bottom: 0.3rem;
    border: 1px solid transparent;
  }
  .base-info {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    .row1 {
      font-size: 0.34rem;
      font-weight: bold;
      color: #333333;
      line-height: 0.48rem;
      margin-bottom: 0.16rem;
    }
    .row2 {
      height: 0.37rem;
      font-size: 0.26rem;
      color: #999999;
      line-height: 0.37rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.12rem;
      .time {
      }
      .department {
      }
    }
    .row3 {
      height: 0.42rem;
      font-size: 0.3rem;
      color: #f6bc38;
      line-height: 0.42rem;
    }
    .approving {
      color: #1989fa;
    }
    .accept {
      color: #07c160;
    }
    .reject {
      color: #ee0a24;
    }
    .cancel {
      color: #f6bc38;
    }
  }

  .values {
    .value-ul {
      .item {
        border-bottom: 1px solid #e5e5e5;
        padding: 0.3rem 0;
        .label {
          height: 0.37rem;
          font-size: 0.26rem;
          color: #999999;
          line-height: 0.37rem;
          margin-bottom: 0.09rem;
        }
        .img-value {
          width: 2rem;
          height: 2rem;
        }
        .file-box {
          margin-bottom: 0.2rem;
          .file-value {
            border-bottom: 1px solid #0094ee;
            color: #0094ee;
          }
        }

        .value {
          font-size: 0.3rem;
          color: #333333;
          line-height: 0.42rem;
          word-break: break-all;
        }
      }
      .item:last-of-type {
        border: none;
      }
    }
  }
  .card-no {
    padding-top: 0.2rem;
    .label {
      color: #999999;
    }
  }

  .process {
    margin-bottom: 1.24rem;
    .title {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      height: 0.42rem;
      font-size: 0.3rem;
      font-weight: bolder;
      color: #333333;
      line-height: 0.42rem;
    }
    .process-ul {
      .item {
        display: flex;
        .left {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 0.9rem;
          margin-right: 0.2rem;
          .avatar {
            width: 0.9rem;
            height: 0.9rem;
            border-radius: 0.45rem;
          }
          .split {
            width: 0.02rem;
            background: #cccccc;
            flex: 1;
          }
        }

        .right {
          flex: 1;
          margin-bottom: 0.6rem;
          .row1 {
            display: flex;
            justify-content: space-between;
            .name {
              font-size: 0.3rem;
              color: #333333;
              line-height: 0.42rem;
            }
            .time {
              font-size: 0.26rem;
              color: #999999;
              line-height: 0.37rem;
              white-space: nowrap;
            }
          }
          .comment-item {
            .comment-content {
              font-weight: 400;
              color: #999999;
              line-height: 0.3rem;
              font-size: 0.12rem;
              margin-top: 0.08rem;
              padding: 0.05rem 0.1rem;
              background: #f2f2f7;
              word-break: break-all;
              margin-bottom: 0.1rem;
            }
            .comment-img {
              padding: 0.05rem 0.1rem;
              background: #f2f2f7;
              margin-bottom: 0.1rem;
              .img {
                width: 0.5rem;
                height: 0.5rem;
                margin-right: 0.05rem;
              }
            }
            .comment-file {
              padding: 0.05rem 0.1rem;
              background: #f2f2f7;
              margin-bottom: 0.1rem;
              .file {
                .file-name {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  cursor: pointer;
                  .file-left {
                    width: 0.4rem;
                    .icon {
                      width: 0.4rem;
                      height: 0.4rem;
                    }
                  }
                  .right-file {
                    margin-top: 0.1rem;
                    .name {
                      font-weight: 400;
                      color: #999999;
                      line-height: 0.2rem;
                      font-size: 0.12rem;
                    }
                    .size {
                      margin-top: 0.05rem;
                      font-weight: 400;
                      color: #999999;
                      line-height: 0.2rem;
                      font-size: 0.12rem;
                    }
                  }
                }
              }
            }
          }
          .status {
            height: 0.37rem;
            font-size: 0.26rem;
            color: #999999;
            line-height: 0.37rem;
          }
          .comment {
            margin-top: 0.2rem;
            width: 5.85rem;
            background: #f1f5f6;
            border-radius: 0 0.16rem 0.16rem 0.16rem;
            padding: 0.16rem;
            font-size: 0.22rem;
            color: #666666;
            line-height: 0.3rem;
          }
        }
      }
      .item:last-of-type {
        .left {
          .split {
            display: none;
          }
        }
        .right {
          margin-bottom: 0.6rem;
        }
      }
    }
  }
  .footer-buttons {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 0.3rem;
    width: 7.5rem;
    height: 1.04rem;
    background: #ffffff;
    box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    .pass {
      width: 2.16rem;
      height: 0.8rem;
      background: #0094ee;
      border-radius: 0.4rem 0 0 0.4rem;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
    }
    .reject-btn {
      width: 2.16rem;
      height: 0.8rem;
      background: #e5f4fd;
      border-radius: 0 0.4rem 0.4rem 0;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
      font-size: 0.26rem;
      color: #0094ee;
      line-height: 0.37rem;
    }

    .icon-text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 2.5rem;
      .img {
        width: 0.4rem;
        height: 0.4rem;
      }
      .text {
        width: 0.52rem;
        height: 0.37rem;
        font-size: 0.26rem;
        color: #333333;
        line-height: 0.37rem;
        white-space: nowrap;
      }
    }
    .icon-text-comment {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 2.5rem;
      .img {
        width: 0.4rem;
        height: 0.4rem;
        font-size: 0.4rem;
        font-weight: 700;
      }
      .text {
        width: 0.52rem;
        height: 0.37rem;
        font-size: 0.26rem;
        color: #333333;
        line-height: 0.37rem;
        white-space: nowrap;
      }
    }
  }

  .approval-box {
    width: 5.7rem;
    background: #ffffff;
    border-radius: 0.16rem;
    .title {
      height: 0.9rem;
      font-size: 0.32rem;
      font-weight: bold;
      color: #333333;
      line-height: 0.45rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .app {
      .message {
        width: 5.3rem;
        height: 2.09rem;
        background: #f4f9fb;
        margin-left: 0.2rem;
        resize: none;
        padding: 0.2rem;
      }

      .footer {
        height: 0.9rem;
        display: flex;
        align-items: center;
        font-size: 0.32rem;
        color: #0094ee;
        line-height: 0.45rem;

        .cancel {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .confirm {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .comment-box {
    padding: 0.3rem;
    .title {
      height: 0.9rem;
      font-size: 0.32rem;
      font-weight: bold;
      color: #333333;
      line-height: 0.45rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .input-text {
      margin: 0.24rem 0rem;
      width: 100%;
      height: 3.09rem;
      background: #f4f9fb;
      border-radius: 0.16rem;
    }
    ::v-deep .van-field__control {
      height: 3rem;
    }
    .upload-btn-box {
      display: flex;
      flex-direction: row;
      .upload-box {
        padding-top: 0.1rem;
        margin-right: 0.1rem;
        ::v-deep .van-uploader {
          border: 0.01rem solid #efefef;
          text-align: center;
          height: 0.6rem;
          line-height: 0.6rem;
          border-radius: 0.08rem;
        }
        ::v-deep .van-uploader__input-wrapper {
          width: 1.5rem !important;
          height: 0.4rem;
          .upload-btn {
            height: 0.4rem;
          }
        }
      }
    }
    .file-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 0.5rem;
      background: #f6f6f6;
      padding-left: 0.1rem;
      align-items: center;
      margin-top: 0.1rem;
      padding: 0rem 0.2rem;
      cursor: pointer;
      .name {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .x {
        width: 0.2rem;
        margin-left: 0.05rem;
      }
    }

    .img-box {
      display: flex;
      flex-direction: row;
      margin: 0.2rem 0rem;
      .img-item {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 1.05rem;
        height: 1.05rem;
        // margin-left: 0.05rem;
        cursor: pointer;
        margin-right: 0.1rem;
        .img {
          width: 1rem;
          height: 1rem;
          border-radius: 0.04rem;
        }
        .x {
          width: 0.2rem;
          margin-left: 0.05rem;
          position: absolute;
          top: 0.02rem;
          right: 0rem;

          color: rgb(245, 94, 94);
        }
      }
    }

    .opt-text {
      margin-top: 0.24rem;
      display: flex;
      width: 100%;
      flex-direction: row;

      span {
        width: 50%;
        height: 0.45rem;
        font-size: 0.32rem;
        font-weight: 400;
        color: #0094ee;
        line-height: 0.45rem;
        text-align: center;
      }
      .sure {
        border-left: 0.01rem solid #f0f0f0;
      }
    }
  }
}
</style>
